import {
  AvailableContainerBoxVariants,
  ContainerBoxInterface
} from '@peakconcepts/landingpage-kit'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 80px 20px 0',
    tablet: '0 80px 20px 0',
    mobile: '0 0 20px 0'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '0 40px 80px',
        tablet: '0 40px 80px',
        mobile: '0 20px 60px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          variant: AvailableContainerBoxVariants.ReverseWidescreen,
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen is-flex is-flex-direction-column is-justify-content-center',
              containers: [
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      textAlign: 'left'
                    },
                    size: 3,
                    text: 'Ein schwerer Fehler mit fatalen Konsequenzen'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '150px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '0 0 30px',
                      tablet: '0 0 30px',
                      mobile: '0 0 30px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Viele Immobilienbesitzer wollen Geld sparen und versuchen Ihre Immobilie selbst zu verkaufen...'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text: '<b>VORSICHT:</b> Sie sparen an der falschen Stelle.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Denn Sie gehen nicht nur das Risiko ein, ihr Eigentum für einen <b>viel zu niedrigen Preis</b> zu verkaufen, sie ignorieren auch die Tatsache, dass der Immobilienmarkt sehr <b>komplex und vielseitig</b> ist.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    margin: {
                      desktop: '0 80px 0 0',
                      tablet: '0 80px 0 0',
                      mobile: '0'
                    },
                    text:
                      'Um einen höchstmöglichen Verkaufspreis zu erzielen, benötigt man <u>mehr als nur ein paar schöne Fotos und eine Anzeige im Internet</u>.'
                  }
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
              containers: [
                {
                  type: 'image',
                  config: {
                    defaultCss: {
                      boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)'
                    },
                    src: '/images/6.jpg'
                  }
                }
              ]
            }
          ]
        },
        {
          type: 'text',
          config: {
            defaultCss: {
              textAlign: 'center'
            },
            margin: {
              desktop: '50px 0 0',
              tablet: '50px 0 0',
              mobile: '20px 0 0'
            },
            text:
              '<u>Fakt ist:</u> Nur durch ein ausgeklügeltes Marketingkonzept erhalten Sie <b>überdurchschnittliche Verkaufspreise.</b>'
          }
        }
      ]
    }
  ]
}

export default data
