import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButtonBewertung'
import colors from '../default/colors'
const descriptionConfig = {
  defaultCss: {
    color: '#fff',
    textAlign: 'left'
  }
}

const data: ContainerBoxInterface = {
  nativeProps: {
    id: 'about'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px 0',
        mobile: '60px 20px 0'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-5-widescreen',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/7.jpg',
                    width: {
                      fullHd: '100%',
                      widescreen: '100%',
                      desktop: '100%',
                      tablet: '100%',
                      mobile: '100%'
                    },
                    margin: {
                      fullHd: '0 10% 0 0',
                      widescreen: '0 10% 0 0',
                      desktop: '0',
                      tablet: '0',
                      mobile: '0'
                    }
                  }
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-7-widescreen pl-0 pr-0',
              containers: [
                {
                  defaultCss: {
                    backgroundColor: colors.header,
                    boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)'
                  },
                  padding: {
                    desktop: '40px',
                    tablet: '40px',
                    mobile: '40px 20px'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 2,
                        defaultCss: {
                          color: '#fff',
                          textAlign: 'left'
                        },
                        text: 'Ihre persönlichen Ansprechpartner'
                      }
                    },
                    {
                      type: 'hr',
                      config: {
                        height: 4,
                        width: '150px',
                        defaultCss: {
                          color: 'primary'
                        },
                        margin: {
                          desktop: '20px 0 30px',
                          tablet: '20px 0 30px',
                          mobile: '20px 0 30px'
                        }
                      }
                    },

                    {
                      type: 'text',
                      config: {
                        text:
                          '„Mein Name ist <b>Gregor Felix Grösser</b>, mein Team von GS Immoservice und ich helfen Ihnen dabei, Ihre Immobilie schnell zu einem <b>Top-Preis</b> zu verkaufen.',
                        ...descriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Durch eine Zusammenarbeit mit uns profitieren Sie von einem umfangreichen Know-how im Immobilienmarkt und erhalten Zugang zu einem <u>riesigen Netzwerk</u> an Immobilienkäufern.',
                        ...descriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Darüber hinaus arbeiten wir in direkter Kooperation mit Anwälten für Immobilien- Erb- und Eherecht zusammen. Bei uns sind sie immer bestens betreut und auch rechtlich zu 100 % abgesichert. Wir finden immer die optimale Lösung für Sie!',
                        ...descriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Für Ihren Erfolg nutzen wir bewährte Konzepte, die all unseren bisherigen Kunden geholfen haben, einen <b>hohen Preis</b> für Ihre Immobilie zu erhalten. Denn wir wissen genau, worauf es beim Immobilienverkauf ankommt:',
                        ...descriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Wir kennen den Markt in- und auswendig und haben bereits über 150 Häuser & Wohnungen so vermarktet, dass Sie bei den richtigen Käufern eine starke Nachfrage auslösten und diese bereit waren, einen lukrativen Preis dafür zu zahlen.“',
                        ...descriptionConfig
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: true,
                        ...ctaButton,
                        margin: {
                          desktop: '50px 0 0',
                          tablet: '50px 0 0',
                          mobile: '30px 0 0'
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
