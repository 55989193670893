// Defaults
import { Content } from '@peakconcepts/landingpage-kit'
import defaultPages from './content/default/defaultPages'
import defaultSettings from './content/default/defaultSettings'

// Pages
import home from './content/pages/home'
import start from './content/pages/start'
import wertermittlung from './content/pages/wertermittlung'
import terminbuchen from './content/pages/terminbuchen'

// Google Ads Pages
import googleAdsWertermittlungHaus from './content/ads/wertermittlung-haus'
import googleAdsHausverkaufErbe from './content/ads/hausverkauf-erbe'

import googleAdsHausScheidung from './content/ads/haus-scheidung'

// Verkauf
import googleAdsVerkaufHaus from './content/ads/verkauf/haus'
import googleAdsVerkaufWohnung from './content/ads/verkauf/wohnung'
import googleAdsVerkaufEinfamilienhaus from './content/ads/verkauf/einfamilienhaus'
import googleAdsVerkaufDoppelhaus from './content/ads/verkauf/doppelhaus'
import googleAdsVerkaufDoppelhaushaelfte from './content/ads/verkauf/doppelhaushaelfte'
import googleAdsVerkaufMehrfamilienhaus from './content/ads/verkauf/mehrfamilienhaus'
import googleAdsVerkaufVilla from './content/ads/verkauf/villa'
import googleAdsVerkaufImmobilie from './content/ads/verkauf/immobilie'
import googleAdsVerkaufEigentumswohnung from './content/ads/verkauf/eigentumswohnung'
import googleAdsVerkaufEtw from './content/ads/verkauf/etw'
import googleAdsVerkaufEigenheim from './content/ads/verkauf/eigenheim'
import googleAdsVerkaufReihenhaus from './content/ads/verkauf/reihenhaus'
import googleAdsVerkaufGrundstueck from './content/ads/verkauf/grundstueck'

const content: Content = {
  ...defaultSettings,
  pages: [
    {
      ...home
    },
    {
      ...start
    },
    {
      ...wertermittlung
    },
    {
      ...terminbuchen
    },
    // Google ads
    {
      ...googleAdsWertermittlungHaus
    },
    {
      ...googleAdsHausverkaufErbe
    },
    {
      ...googleAdsHausScheidung
    },
    /** Verkauf */
    {
      ...googleAdsVerkaufHaus
    },
    {
      ...googleAdsVerkaufWohnung
    },
    {
      ...googleAdsVerkaufEinfamilienhaus
    },
    {
      ...googleAdsVerkaufDoppelhaus
    },
    {
      ...googleAdsVerkaufDoppelhaushaelfte
    },
    {
      ...googleAdsVerkaufMehrfamilienhaus
    },
    {
      ...googleAdsVerkaufVilla
    },
    {
      ...googleAdsVerkaufImmobilie
    },
    {
      ...googleAdsVerkaufEigentumswohnung
    },
    {
      ...googleAdsVerkaufDoppelhaushaelfte
    },
    {
      ...googleAdsVerkaufEtw
    },
    {
      ...googleAdsVerkaufEigenheim
    },
    {
      ...googleAdsVerkaufReihenhaus
    },
    {
      ...googleAdsVerkaufGrundstueck
    },
    ...defaultPages
  ]
}
export default content
