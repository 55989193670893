import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemImageConfig = {
  height: {
    desktop: '100px'
  },
  margin: {
    desktop: '0 0 30px',
    tablet: '40px 0 30px',
    mobile: '20px 0 20px'
  }
}

const data: ContainerBoxInterface = {
  nativeProps: {
    id: 'vorteile'
  },
  defaultCss: {
    backgroundColor: '#fafafa'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            text: 'Warum Sie unbedingt einen Makler beauftragen sollten:'
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 60px',
              tablet: '30px auto',
              mobile: '30px auto'
            }
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-12-mobile is-12-tablet is-4-desktop',
              defaultCss: {
                textAlign: 'center'
              },
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/ic_safety.png',
                    ...itemImageConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    size: 3,
                    text: 'Mehr Sicherheit',
                    defaultCss: {
                      textAlign: 'center'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      textAlign: 'center'
                    },
                    text:
                      'Sie können sich ruhig und sicher fühlen, da ein Experte den vollständigen Verkaufsprozess für Sie übernimmt. Dank unseren Fachkenntnissen wissen Sie genau, wie viel Ihre Immobilie wert ist und müssen keine Angst haben, Ihr Eigentum unter Wert zu verkaufen.'
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-4-desktop',
              defaultCss: {
                textAlign: 'center'
              },
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/ic_time.png',
                    ...itemImageConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    size: 3,
                    text: 'Mehr Zeit',
                    defaultCss: {
                      textAlign: 'center'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      textAlign: 'center'
                    },
                    text:
                      'Durch eine Zusammenarbeit mit uns können Sie sich entspannt zurücklehnen und sich auf Ihre persönlichen Dinge konzentrieren, während wir den passenden Käufer für Sie finden. Wir kennen uns mit den Abläufen genau aus, und übernehmen die gesamte Abwicklung für Sie.'
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-4-desktop',
              defaultCss: {
                textAlign: 'center'
              },
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/ic_money.png',
                    ...itemImageConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    size: 3,
                    text: 'Höherer Verkaufspreis',
                    defaultCss: {
                      textAlign: 'center'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      textAlign: 'center'
                    },
                    text:
                      'Mit unserer Strategie heben wir die Vorteile Ihrer Immobilie heraus und steigern die Nachfrage Ihrer Zielgruppe enorm. Wir sprechen mit potenziellen Interessenten und nutzen dabei Profi-Verhandlungstechniken, um Ihr Objekt optimal zu präsentieren und einen hohen Verkaufspreis für Sie durchzusetzen.'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
