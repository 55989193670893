import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../default/colors'

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0 10px',
        widescreen: '80px 0 10px',
        desktop: '80px 40px 10px',
        tablet: '80px 40px 10px',
        mobile: '60px 20px 10px'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            margin: {
              desktop: '0',
              tablet: '0 20px',
              mobile: '0 0 20px'
            },
            text: 'Wir wissen, Ihre Zeit ist Ihr kostbarstes Gut!'
          }
        },
        {
          type: 'text',
          config: {
            defaultCss: {
              color: colors.header,
              textAlign: 'center'
            },
            margin: {
              desktop: '0',
              tablet: '0',
              mobile: '0 10px'
            },
            fontSize: {
              desktop: '30px',
              tablet: '24px',
              mobile: '20px'
            },
            text:
              'Deshalb übernehmen wir den <b>kompletten Verkaufsprozess</b> für Sie.'
          }
        }
      ]
    }
  ]
}

export default data
