import { ContainerBox } from '@peakconcepts/landingpage-kit'

import seoHeaderData from '../../content/data/seoHeader'
import configuratorWithoutHero from '../../content/data/configuratorWithoutHero'
import openingData from '../../content/data/opening'
import goodNewsData from '../../content/data/goodNews'
import optionsData from '../../content/data/options'
import whyData from '../../content/data/why'
import consequencesData from '../../content/data/consequences'
import advantagesData from '../../content/data/advantages'
import infoData from '../../content/data/info'
import aboutData from '../../content/data/about'
import processData from '../../content/data/process'
import contactData from '../../content/data/contact'
import faqsData from '../../content/data/faqs'

export const start = {
  path: '/start',
  headerEnabled: true,
  seoHeaderConfig: seoHeaderData.main,
  headerConfig: {
    menuEndConfig: undefined
  },
  sections: [
    {
      component: ContainerBox,
      data: {
        ...configuratorWithoutHero
      }
    },
    {
      component: ContainerBox,
      data: {
        ...openingData
      }
    },
    {
      component: ContainerBox,
      data: {
        ...goodNewsData
      }
    },
    {
      component: ContainerBox,
      data: {
        ...optionsData
      }
    },
    {
      component: ContainerBox,
      data: {
        ...whyData
      }
    },
    {
      component: ContainerBox,
      data: {
        ...consequencesData
      }
    },
    {
      component: ContainerBox,
      data: {
        ...advantagesData
      }
    },
    {
      component: ContainerBox,
      data: {
        ...infoData
      }
    },
    {
      component: ContainerBox,
      data: {
        ...aboutData
      }
    },
    {
      component: ContainerBox,
      data: {
        ...processData
      }
    },
    {
      component: ContainerBox,
      data: {
        ...contactData
      }
    },
    {
      component: ContainerBox,
      data: {
        ...faqsData
      }
    }
  ]
}

export default start
