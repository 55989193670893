import seoHeaderData from '../../content/data/seoHeader'
import homeSections from '../sections/home'

export const home = {
  path: '/',
  headerEnabled: true,
  seoHeaderConfig: seoHeaderData.main,
  sections: homeSections
}

export default home
