import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../content/default/colors'

const itemCardConfig = {
  defaultCss: {
    textAlign: 'center',
    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    padding: '50px 30px 30px',
    backgroundColor: '#fff'
  },
  margin: {
    desktop: '40px 0 0',
    tablet: '40px 0 0',
    mobile: '40px 0 0'
  }
}

const itemTitleConfig = {
  size: 6,
  defaultCss: {
    textAlign: 'center'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'center',
    fontStyle: 'italic'
  }
}

const itemNameConfig = {
  defaultCss: {
    textAlign: 'center'
  }
}

const itemImageConfig = {
  height: {
    desktop: '100px'
  },
  defaultCss: {
    borderRadius: '50%'
  },
  margin: {
    desktop: '-90px 0 30px',
    tablet: '-90px 0 30px',
    mobile: '-90px 0 20px'
  }
}

const data: ContainerBoxInterface = {
  nativeProps: {
    id: 'vorteile'
  },
  defaultCss: {
    backgroundColor: colors.secondary
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            text: 'Das sagen unsere Kunden'
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto',
              mobile: '30px auto'
            }
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/reviews/schlichter.jpg',
                        ...itemImageConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: 'Kompetent und vertrauensvoll',
                        ...itemTitleConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '“Ich konnte unser geerbtes Haus innerhalb kürzester Zeit über dem Marktwert verkaufen. Durch die Fachkompetenz und die vertrauensvolle Art von Herrn Grösser lief alles perfekt ab.”',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: '⭐⭐⭐⭐⭐ <br/>Silvia Schlichter',
                        ...itemNameConfig
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/reviews/meinert.jpg',
                        ...itemImageConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: 'Super angenehmer Service',
                        ...itemTitleConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '“Von der Bewertung des Hauses bis zur Kaufvertragsunterschrift lief alles super angenehm und einfach für uns ab. Herr Grösser hatte die richtige Verkaufsstrategie für unser Haus und konnte innerhalb sehr kurzer Zeit einen spitzen Preis erzielen.”',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: '⭐⭐⭐⭐⭐ <br/>Natalia und Krystian Meinert',
                        ...itemNameConfig
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/reviews/mueller.jpg',
                        ...itemImageConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: 'Höherer Preis als angenommen',
                        ...itemTitleConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '“Herr Grösser von GS Immoservice ist mir von Freunden weiterempfohlen worden und das zu Recht. Der Verkauf meiner Wohnung klappte sensationell gut und zu einem deutlich höheren Preis als angenommen.”',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: '⭐⭐⭐⭐⭐ <br/>Ralph Müller',
                        ...itemNameConfig
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
