import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const titleConfig = {
  size: 2,
  margin: {
    desktop: '0 0 20px 0',
    tablet: '0 30px 20px 0',
    mobile: '0 0 20px 0'
  }
}

const descriptionConfig = {
  margin: {
    desktop: '0 200px 30px',
    tablet: '0 10px 30px',
    mobile: '0 0 40px 0'
  },
  defaultCss: {
    textAlign: 'center'
  }
}

const itemTitleConfig = {
  size: 3,
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    fullHd: '0 0 30px 40px',
    widescreen: '0 0 30px 0',
    desktop: '0 0 30px 0',
    tablet: '0 0 30px 0',
    mobile: '0 0 30px 0'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    fullHd: '0 80px 20px 40px',
    widescreen: '0 0 20px 0',
    desktop: '0 0 20px 0',
    tablet: '0 80px 20px 0',
    mobile: '0 0 20px 0'
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundColor: '#fafafa'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            text: 'Wie verkauft man eine Immobilie zum TOP-Preis?',
            ...titleConfig
          }
        },
        {
          type: 'text',
          config: {
            text:
              'Sie fragen sich wie Sie Ihre Immobilie <b>so lukrativ wie möglich</b> verkaufen können? Doch irgendwie wissen Sie nicht, <u>wie sie vorgehen sollen</u>…',
            ...descriptionConfig
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 50px'
            }
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
              containers: [
                {
                  type: 'image',
                  config: {
                    defaultCss: {
                      boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)'
                    },
                    src: '/images/1.jpg'
                  }
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen is-flex is-flex-direction-column is-justify-content-center',
              containers: [
                {
                  type: 'text',
                  config: {
                    text: 'Sie haben zu viele<br/> ungeklärte Fragen im Kopf?',
                    ...itemTitleConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      '„Wie viel ist meine Immobilie <u>aktuell</u> wert und welchen Preis soll ich ansetzen?“',
                    ...itemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      '„Wie finde ich den <u>optimalen</u> Käufer, der auch den Preis bezahlt, den ich haben will?“',
                    ...itemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      '„Und welche Fehler sollte ich beim Verhandeln <u>unbedingt</u> vermeiden?“',
                    ...itemDescriptionConfig,
                    margin: {
                      fullHd: '0 80px 0 40px',
                      widescreen: '0',
                      desktop: '0',
                      tablet: '0 80px 0 0',
                      mobile: '0'
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
