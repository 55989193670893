import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemTitleConfig = {
  size: 3,
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    fullHd: '0 0 10px 40px',
    widescreen: '0 0 10px 0',
    desktop: '0 0 10px 0',
    tablet: '0 0 10px 0',
    mobile: '0 0 10px 0'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    fullHd: '0 0 20px 40px',
    widescreen: '0 0 20px 0',
    desktop: '0 0 20px 0',
    tablet: '0 80px 20px 0',
    mobile: '0 0 20px 0'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
              containers: [
                {
                  type: 'image',
                  config: {
                    defaultCss: {
                      boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)'
                    },
                    src: '/images/5.jpg'
                  }
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen is-flex is-flex-direction-column is-justify-content-center',
              containers: [
                {
                  type: 'text',
                  config: {
                    text: 'Warum es strategisch klug ist, JETZT zu verkaufen',
                    ...itemTitleConfig
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '150px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      fullHd: '20px 0 30px 40px',
                      widescreen: '20px 0 30px 0',
                      desktop: '20px 0 30px 0',
                      tablet: '20px 0 30px',
                      mobile: '20px 0 30px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    text: 'Es ist kein Geheimnis: Der Immobilienmarkt boomt.',
                    ...itemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Das aktuelle Zinsniveau ist niedriger denn je und erhöht die Nachfrage nach Immobilien so stark wie nie zuvor.',
                    ...itemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      '<b>Die wahren Gewinner der aktuellen Situation sind die Verkäufer.</b>',
                    ...itemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Statistiken belegen, dass in den letzten Jahren Immobilien für durchschnittlich 20 % - 30 % <b>über</b> dem eigentlichen Marktwert verkauft wurden.',
                    ...itemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      '<u>Beispiel:</u><br/>Für ein Objekt, das objektiv 300.000 € wert ist, kann 360.000 € oder sogar 390.000 € erzielt werden.',
                    ...itemDescriptionConfig,
                    margin: {
                      fullHd: '0 80px 0 40px',
                      widescreen: '0',
                      desktop: '0',
                      tablet: '0 80px 0 0',
                      mobile: '0'
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
