import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButtonBewertung'

const titleConfig = {
  size: 3,
  defaultCss: {
    textAlign: 'left'
  },
  padding: {
    desktop: '10px 10px 0 10px',
    tablet: '10px 10px 0 10px',
    mobile: '10px 0 0 0'
  },
  margin: {
    desktop: '0 0 30px',
    tablet: '0 0 30px',
    mobile: '0 0 30px'
  }
}

const descriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  padding: {
    desktop: '0 10px 0 0',
    tablet: '0 10px 0 0',
    mobile: '0 10px 0 0'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemCancelIconConfig = {
  size: 7,
  icon: 'cancel',
  padding: {
    desktop: '0 0 0 10px',
    tablet: '0 0 0 10px',
    mobile: '0'
  },
  defaultCss: {
    color: '#bf4248'
  }
}

const itemDoneIconConfig = {
  size: 7,
  icon: 'done',
  padding: {
    desktop: '0 0 0 10px',
    tablet: '0 0 0 10px',
    mobile: '0'
  },
  defaultCss: {
    color: '#37761d'
  }
}

const boxShadow = {
  defaultCss: {
    backgroundColor: '#fff',
    boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)'
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundColor: 'rgb(232, 238, 249)'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '60px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-full',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 2,
                    text: 'Sie haben 2 Optionen'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '150px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '30px auto 30px',
                      tablet: '30px auto 20px',
                      mobile: '30px auto 20px'
                    }
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-tablet is-6-desktop is-flex',
              containers: [
                {
                  ...boxShadow,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/3.jpg'
                      }
                    },
                    {
                      classNames: 'column',
                      containers: [
                        {
                          type: 'text',
                          config: {
                            text: 'Immobilie selbst verkaufen',
                            ...titleConfig
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                '<b>Hohes Risiko,</b> die Immobilie unter Wert zu verkaufen',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemCancelIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                '<b>Niedriger Verkaufspreis,</b> durch fehlende Erfahrung im Verkauf',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemCancelIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                '<b>Lange Verkaufszeit</b> aufgrund fehlender Strategie',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemCancelIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                '<b>Permanente Gefahr,</b> rechtlich teure Fehler zu machen',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemCancelIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                '<b>Hohes Zeitinvestment,</b> da Sie sich um den ganzen Papierkram selbst kümmern müssen',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemCancelIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                '<b>Besichtigungs-Kriminalität,</b> können Sie sich schützen?',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemCancelIconConfig
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-tablet is-6-desktop is-flex',
              containers: [
                {
                  ...boxShadow,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/4.jpg'
                      }
                    },
                    {
                      classNames: 'column',
                      containers: [
                        {
                          type: 'text',
                          config: {
                            text: 'Makler beauftragen',
                            ...titleConfig
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                '<b>Verkaufen über Marktwert,</b> dank einem ausgeklügelten Marketingkonzept',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemDoneIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                '<b>Mehr Sicherheit,</b> dank richtiger Schätzung',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemDoneIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                '<b>Schneller Verkauf,</b> durch großes Netzwerk',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemDoneIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                '<b>Glasklare Vorgehensweise</b> von Anfang bis Ende',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemDoneIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                '<b>Minimaler Aufwand,</b> weil ein persönlicher Ansprechpartner die ganze Abwicklung für Sie übernimmt',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemDoneIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                '<b>Interessenten,</b> personalisiert und finanziell vorgeprüft',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemDoneIconConfig
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          type: 'cta',
          config: {
            dark: false,
            ...ctaButton,
            margin: {
              desktop: '50px 0 0',
              tablet: '50px 0 0',
              mobile: '30px 0 0'
            }
          }
        }
      ]
    }
  ]
}

export default data
