import { ContainerBox } from '@peakconcepts/landingpage-kit'
import seoHeaderData from '../data/seoHeader'
import configuratorOnly from '../data/configuratorOnly'

export const wertermittlung = {
  path: '/wertermittlung',
  headerEnabled: true,
  seoHeaderConfig: seoHeaderData.main,
  headerConfig: {
    menuEndConfig: undefined
  },
  sections: [
    {
      component: ContainerBox,
      data: {
        ...configuratorOnly
      }
    }
  ]
}

export default wertermittlung
