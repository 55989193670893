import Text from '@peakconcepts/landingpage-kit/lib/components/elements/Text'
import Image from '@peakconcepts/landingpage-kit/lib/components/elements/Image'
import Modal from '@peakconcepts/landingpage-kit/lib/components/elements/Modal'
import Questionnaire from '@peakconcepts/landingpage-kit/lib/components/elements/Questionnaire'
import Loader from '@peakconcepts/landingpage-kit/lib/components/elements/Loader'

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import questionnairyData from '../content/data/questionnairyData'

const Select = styled.div<any>`
  outline: none;
  margin: 0;
  padding: 5px;
  .content {
    background: #fff;
    box-shadow: rgb(0 0 0 / 16%) 5px 5px 20px;
    border: ${({ active, mainColor }) =>
      active ? `2px solid ${mainColor}` : '1px solid #ddd;'};
  }
  p {
    margin: 0;
    font-size: 16px;
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.005);
    .content {
      box-shadow: 5px 5px 20px rgb(0 0 0 / 30%);
      transition: box-shadow 0.25s linear, -webkit-box-shadow 0.25s linear;
    }
  }
  @media (max-width: 767px) {
    p {
      font-size: 13px;
    }
  }
  @media (max-width: 340px) {
    p {
      font-size: 12px;
    }
  }
`

const QuestionnaireSection = () => {
  const question = questionnairyData.questions[0] || {}
  const answers = question?.answers || []
  const [selectedStep, setSelectedStep] = useState(0)
  const [initAnswer, setInitAnswer] = useState<string | undefined>()
  const [showModal, setShowModal] = useState(false)
  const [showModalLoading, setShowModalLoading] = useState(false)
  const [showCloseButton, setShowCloseButton] = useState(true)
  const closeModal = () => {
    setShowModal(false)
  }
  const goToStep = (answer: any) => {
    setInitAnswer(answer.descriptionConfig.text)
    setSelectedStep(answer.nextStep || question.nextStep)
    setShowModalLoading(true)
  }
  useEffect(() => {
    if (showModalLoading) {
      // Render Questionnaire in background after first render cycle
      setTimeout(() => {
        setShowModal(true)
      }, 1)
      setTimeout(() => {
        // Hide Loader with delay so questuionnaire has time to init
        setShowModalLoading(false)
      }, 1000)
    }
  }, [showModalLoading])

  return (
    <>
      <div className="columns is-marginless is-multiline is-mobile">
        {answers.map((answer: any, index: number) => {
          return (
            <Select
              className={
                answers.length < 5
                  ? 'column is-6-mobile is-3-tablet is-3-desktop no-user-select'
                  : 'column is-full no-user-select'
              }
              key={`question_${index}`}
              onClick={() => {
                goToStep(answer)
              }}>
              <div className="content no-user-select">
                <div className="columns is-marginless is-multiline is-mobile">
                  {answer.image && (
                    <div
                      className={
                        answers.length < 5
                          ? 'column is-full'
                          : 'column is-4-mobile is-2-tablet is-2-desktop'
                      }>
                      <Image {...answer.image} />
                    </div>
                  )}
                  {answer.descriptionConfig && (
                    <div
                      className={
                        answers.length < 5
                          ? 'column is-full pt-0'
                          : 'column is-8-mobile is-10-tablet is-10-desktop'
                      }
                      style={
                        answers.length < 5
                          ? {}
                          : {
                              display: 'flex',
                              alignItems: 'center',
                              paddingLeft: 0
                            }
                      }>
                      <Text
                        {...answer.descriptionConfig}
                        defaultCss={{
                          textAlign: answers.length < 5 ? 'center' : 'left',
                          wordBreak: 'break-all'
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Select>
          )
        })}
      </div>
      <Modal
        showModal={showModal || showModalLoading}
        showCloseButton={showCloseButton}
        closeModal={closeModal}
        backgroundColor={'#fff'}>
        <div className="columns is-multiline">
          <div className="column is-12-mobile is-offset-0-tablet is-12-tablet is-offset-0-desktop is-12-desktop is-offset-2-widescreen is-8-widescreen">
            {showModalLoading && (
              <Loader
                mainColor={questionnairyData.mainColor}
                backgroundColor="white">
                <div className="loader">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <p>Konfigurator wird gestartet</p>
              </Loader>
            )}
            {showModal && (
              <Questionnaire
                {...questionnairyData}
                initStep={selectedStep}
                initAnswer={initAnswer}
                setShowCloseButton={setShowCloseButton}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default QuestionnaireSection
