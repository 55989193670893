import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import configuratorData from './configurator'

const data: ContainerBoxInterface = {
  containers: [
    {
      defaultCss: {
        backgroundColor: 'rgba(232, 238, 249, .5)',
        media: [
          {
            breakpoint: 'mobile',
            css: { background: 'none' }
          }
        ]
      },
      containers: [
        {
          nativeProps: {
            id: 'configurator'
          },
          classNames: 'container',
          padding: {
            fullHd: '100px 0',
            widescreen: '100px 0',
            desktop: '100px 40px',
            tablet: '100px 40px',
            mobile: '70px 0 0 0'
          },
          ...configuratorData
        }
      ]
    }
  ]
}

export default data
