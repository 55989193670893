import { ContainerBox } from '@peakconcepts/landingpage-kit'
import configuratorDataHome from '../../content/data/configuratorHome'

import heroData from '../../content/data/hero'
import openingData from '../../content/data/opening'
import goodNewsData from '../../content/data/goodNews'
import optionsData from '../../content/data/options'
import whyData from '../../content/data/why'
import consequencesData from '../../content/data/consequences'
import advantagesData from '../../content/data/advantages'
import infoData from '../../content/data/info'
import reviewData from '../../content/data/reviews'
import aboutData from '../../content/data/about'
import processData from '../../content/data/process'
import contactData from '../../content/data/contact'
import faqsData from '../../content/data/faqs'

const data = [
  {
    id: 'heroData',
    component: ContainerBox,
    data: {
      ...heroData
    }
  },
  {
    id: 'configuratorData',
    component: ContainerBox,
    data: {
      ...configuratorDataHome
    }
  },
  {
    id: 'openingData',
    component: ContainerBox,
    data: {
      ...openingData
    }
  },
  {
    id: 'goodNewsData',
    component: ContainerBox,
    data: {
      ...goodNewsData
    }
  },
  {
    id: 'optionsData',
    component: ContainerBox,
    data: {
      ...optionsData
    }
  },
  {
    id: 'whyData',
    component: ContainerBox,
    data: {
      ...whyData
    }
  },
  {
    id: 'consequencesData',
    component: ContainerBox,
    data: {
      ...consequencesData
    }
  },
  {
    id: 'advantagesData',
    component: ContainerBox,
    data: {
      ...advantagesData
    }
  },
  {
    id: 'reviewData',
    component: ContainerBox,
    data: {
      ...reviewData
    }
  },
  {
    id: 'infoData',
    component: ContainerBox,
    data: {
      ...infoData
    }
  },
  {
    id: 'aboutData',
    component: ContainerBox,
    data: {
      ...aboutData
    }
  },
  {
    id: 'processData',
    component: ContainerBox,
    data: {
      ...processData
    }
  },
  {
    id: 'contactData',
    component: ContainerBox,
    data: {
      ...contactData
    }
  },
  {
    id: 'faqsData',
    component: ContainerBox,
    data: {
      ...faqsData
    }
  }
]

export default data
