import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import QuestionnaireSection from '../../components/QuestionnaireSection'

const data: ContainerBoxInterface = {
  containers: [
    {
      defaultCss: {
        boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
        border: '2px solid #63b65c',
        position: 'relative',
        background: '#fff',
        media: [
          {
            breakpoint: 'mobile',
            css: { boxShadow: 'none', border: 'none' }
          }
        ]
      },
      padding: {
        fullHd: '100px 0 60px',
        widescreen: '100px 0 60px',
        desktop: '100px 40px 60px',
        tablet: '100px 40px 40px',
        mobile: '100px 30px 40px'
      },
      containers: [
        {
          containers: [
            {
              defaultCss: {
                position: 'absolute',
                background: '#63b65c',
                zIndex: '2',
                top: '0',
                left: '0',
                right: '0',
                padding: '15px 0'
              },
              containers: [
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      textAlign: 'center',
                      color: '#fff'
                    },
                    margin: {
                      desktop: '0',
                      tablet: '0',
                      mobile: '0'
                    },
                    text:
                      '<b>Persönliche Wertermittlung durch erfahrenen Experten Vor-Ort</b>'
                  }
                }
              ]
            },
            {
              defaultCss: {
                position: 'absolute',
                borderTop: 'solid 40px #63b65c',
                borderLeft: 'solid 50px transparent',
                borderRight: 'solid 50px transparent',
                zIndex: '1',
                top: '30px',
                left: '50%',
                transform: 'translate(-50%)',
                media: [
                  {
                    breakpoint: 'mobile',
                    css: { top: '50px' }
                  }
                ]
              },
              containers: [
                {
                  type: 'text',
                  config: {
                    text: ''
                  }
                }
              ]
            },
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames: 'column is-full pl-0 pr-0',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 2,
                        margin: {
                          desktop: '0 0 15px',
                          tablet: '0 0 15px',
                          mobile: '0 0 15px'
                        },
                        text:
                          'Wie hoch ist der<br/> aktuelle Marktwert Ihrer Immobilie?'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        defaultCss: {
                          textAlign: 'center'
                        },
                        margin: {
                          desktop: '20px',
                          tablet: '20px',
                          mobile: '0'
                        },
                        text:
                          'Finden Sie es jetzt <b>einfach & unkompliziert</b> mit einem <u>kostenlosen</u> Immobilien-Check heraus!'
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-10-desktop is-offset-1-desktop is-10-widescreen is-offset-1-widescreen pl-0 pr-0',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        defaultCss: {
                          textAlign: 'center'
                        },
                        text: 'Welche Immobilie möchten Sie <b>verkaufen</b>?'
                      }
                    },
                    {
                      type: 'component',
                      component: QuestionnaireSection
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
