import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButtonBewertung'

const data: ContainerBoxInterface = {
  nativeProps: {
    id: 'contact'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            text: 'Möchten Sie Ihre Immobilie zum TOP-Preis verkaufen?',
            size: 2
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          type: 'text',
          config: {
            defaultCss: {
              textAlign: 'center'
            },
            margin: {
              desktop: '0 120px',
              tablet: '0',
              mobile: '0'
            },
            text:
              'Dann klicken Sie jetzt auf den Button und tragen Sie die Eckdaten Ihrer Immobilie ein. Ein Experte wird Sie innerhalb von 1-2 Tagen telefonisch kontaktieren, um offene Fragen zu klären und einen Termin für die kostenlose Wertermittlung bei Ihnen Vor-Ort zu vereinbaren. Im Anschluss erhalten Sie dann Ihre Wertermittlung mit dem tagesaktuellen Verkaufspreis Ihrer Immobilie!'
          }
        },
        {
          type: 'cta',
          classNames: 'is-12-mobile is-12-tablet',
          config: {
            dark: false,
            ...ctaButton,
            margin: {
              desktop: '50px 0 0',
              tablet: '50px 0 0',
              mobile: '30px 0 0'
            }
          }
        }
      ]
    }
  ]
}

export default data
