const data = {
  sectionConfig: {
    padding: {
      desktop: '40px 0',
      tablet: '40px 0',
      mobile: '40px 0'
    }
  },
  company: 'GS Immoservice UG (haftungsbeschränkt)<br/> Durlacher Strasse 35<br/> 75172 Pforzheim',
  provider: 'ALL-INKL',
  customCookieScriptTag: {
    id: 'CookieDeclaration',
    src:
      'https://consent.cookiebot.com/100c888b-db58-4d4c-8b10-5f69f84408eb/cd.js',
    type: 'text/javascript',
    async: true
  }
}

export default data
