import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../default/colors'

const itemCardConfig = {
  defaultCss: {
    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    padding: '50px 30px 30px',
    backgroundColor: '#fff'
  },
  margin: {
    desktop: '60px 0 0',
    tablet: '40px 0 0',
    mobile: '40px 0 0'
  }
}

const stepConfig = {
  size: 5,
  margin: {
    desktop: '10px 0 10px'
  }
}

const stepTitleConfig = {
  size: 5,
  margin: {
    desktop: '0 0 20px'
  }
}

const stepDescriptionConfig = {
  defaultCss: {
    textAlign: 'center'
  }
}

const itemIconContainer = {
  defaultCss: {
    position: 'absolute',
    width: '100%',
    top: '-40px',
    left: '0',
    textAlign: 'center'
  }
}

const itemIconConfig = {
  size: 8,
  margin: {
    desktop: '0 auto'
  },
  defaultCss: {
    color: '#fff',
    margin: 'auto',
    width: '80px',
    height: '80px',
    padding: '20px',
    borderRadius: '50%',
    background: colors.primary
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundColor: '#fafafa'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '60px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            text: 'Wie funktioniert die kostenlose Wertermittlung?'
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'date_range',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            ...stepConfig,
                            text: '<span>Schritt 1:</span>'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...stepTitleConfig,
                            text: 'Immobilie beschreiben'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...stepDescriptionConfig,
                            text:
                              'Nutzen Sie den kostenlosen Immobilien-Check. Tragen Sie die Eckdaten Ihrer Immobilie und Ihre Kontaktdaten ein, um eine kostenlose Wertermittlung zu sichern.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'call',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            ...stepConfig,
                            text: '<span>Schritt 2:</span>'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...stepTitleConfig,
                            text: 'Vor-Ort-Termin vereinbaren'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...stepDescriptionConfig,
                            text:
                              'Ein Experte wird Sie innerhalb von 1-2 Tagen telefonisch kontaktieren, um offene Fragen zu klären und einen Termin für die kostenlose Wertermittlung bei Ihnen Vor-Ort zu vereinbaren.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'trending_up',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            ...stepConfig,
                            text: '<span>Schritt 3:</span>'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...stepTitleConfig,
                            text: 'Verkaufspreis erfahren'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...stepDescriptionConfig,
                            text:
                              'Ein Experte begutachtet Vor-Ort den Zustand, Lage und Ausstattung Ihrer Immobilie. Im Anschluss erhalten Sie den tagesaktuellen Verkaufspreis Ihrer Immobilie.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
