import { Calendly } from '@peakconcepts/landingpage-kit'
import generalData from '../../content/default/data/general'
import seoHeaderData from '../../content/data/seoHeader'
import { headerConfig } from '../../content/default/defaultPages'

export const terminbuchen = {
  path: '/termin-buchen',
  headerEnabled: true,
  headerConfig: {
    ...headerConfig,
    containerConfig: {
      className: 'is-hidden-mobile',
      padding: {
        fullHd: '20px 0',
        widescreen: '20px 0',
        desktop: '20px 0',
        tablet: '20px 0',
        mobile: '10px 0'
      },
      defaultCss: {
        backgroundColor: 'unset'
      }
    }
  },
  seoHeaderConfig: seoHeaderData.main,
  component: Calendly,
  data: {
    ...generalData.calendly
  }
}

export default terminbuchen
